<template>
	<div class="display-flex flex-wrap">
		<div class="item boderra8 bg-white marb20 cure-pointer" v-for="(item, i) in lists" :key="i" @click="toDetail(item.id)">
			<div class="">
				<!-- <img class="item-pic display-block" :src="item.image" alt="" /> -->
				<el-image class="item-pic display-block"  :src="item.image" lazy fit="cover"></el-image>
			</div>
			<div class="paddlr16">
				<div class="paddt20 paddb27 fz20 co-333333 one-point font-blod">{{item.title}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			list: {
				type: Array,
				default () {
					return []
				}
			}
		},
		data() {
			return {
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				lists: ''
			}
		},
		watch: {
			list(newval) {
				this.lists = newval
		
			},
		},
		created() {
			this.lists = this.list
		
		},
		methods: {
			// 跳转详情
			toDetail(id) {
				this.$router.push({
					path:'/Detail',
					query: { type: 4,
							id:id
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.item {
		width: 285px;
		margin-right: 20px;
		box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
		
		.item-pic {
			width: 285px;
			height: 220px;
			border-radius: 8px 8px 0px 0px;
		}
	}
	.item:nth-child(4n) {
		margin-right: 0;
	}
	
</style>
