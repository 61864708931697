<template>
	<div>
		<div class="swiper-box relative">
			<!-- 轮播 -->
			<div class="swiper-index swiper-box-index">
				<swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :autoplay="true" :pagination="pagination"
					:navigation="navigation" class="mySwiper">
					<swiper-slide v-for="(item,index) in pagesinfo.carousel" :key='index' @click="toPart(item)">
						<el-image class="swiper-pic display-block w100 " :src="item.pc_image" style="height: 600px;"
							fit="fill"></el-image>
					</swiper-slide>
					<div class="swiper-button-prev"></div>
					<div class="swiper-button-next"></div>
				</swiper>
			</div>
		</div>

		<div class="center flex-column">
			<div class="w1200">
				<div class="h100px align-items-center fz40 co-333333">院校指南</div>
			</div>

			<!-- 列表 -->
			<div class="w100 bg-F7F7F7 center flex-column">
				<div class="w1200">
					<div class="justify-content-center-between paddt20 paddb20">
						<div class="align-items-center">
							<span class="fz20 co-333333 " style="width: 70px;">国家</span>
							<div class="display-flex flex-wrap">
							<div class="title-item center cure-pointer fz14" v-for="(item, i) in pagesinfo.country"
								:key="i"
								:class="[titleIndex == i ? 'bg-DCFF03 co-010085' : 'co-333333', i == 0 ? 'marl10' : '']"
								@click="titleChoose(i)">{{item.name}}</div>
							</div>		
						</div>

						<div class="center sort cure-pointer relative" @click="pullClick">
							<span class="fz14 co-010085">{{titleListfont}}</span>
							<img class="pull-pic w100 marl4 display-block" :class="pull ? 'rotate' : ''"
								src="@/assets/img/pull.png" alt="">
							<img class="triangle display-block absolute" v-if="pull" src="@/assets/img/triangle.png"
								alt="" />
							<div class="absolute pull-box z100" :style="`height: ${pull ? '130px' : '0px'};`">
								<div class="justify-content-center-between paddlr12 h40" v-for="(item, i) in selectList"
									:key="i" @click.stop="selectChoose(i)">
									<span class="fz14"
										:class="seletIndex == i ? 'co-010085' : 'co-333333'">{{item}}</span>
									<img class="check_icon display-block" v-if="seletIndex == i"
										src="@/assets/img/check_icon.png" alt="" />
								</div>
							</div>
						</div>
					</div>

					<div class="justify-content-center-between paddb40">
						<div class="align-items-center">
							<span class="fz20 co-333333" style="width: 87px;"> 专业</span>
							<div class="display-flex flex-wrap">
							<div class="title-item center cure-pointer fz14" v-for="(item, i) in pagesinfo.profess"
								:key="i"
								:class="[titleListTwoIndex == i ? 'bg-DCFF03 co-010085' : 'co-333333', i == 0 ? 'marl10' : '']"
								@click="titleTwoChoose(i)">{{item.name}}</div>
							</div>	
						</div>
					</div>
					<div style="min-height: 1120px;">
					<schoolguide :list="list"></schoolguide>
					</div>
					<loadMore :state="state" loading_icon></loadMore>
					<div class="center paddt54 paddb15">
						<el-pagination background  class="center "  @current-change="handleCurrentChange"
						    layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
			<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>
</template>

<script>
	// Import Swiper Vue.js components
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';

	// Import Swiper styles
	import 'swiper/swiper.less';
	import schoolguide from '@/components/schoolguide.vue';
	import "swiper/components/pagination/pagination.min.css"
	import "swiper/components/navigation/navigation.min.css"

	import SwiperCore, {
		Pagination,
		Navigation,
		Autoplay
	} from 'swiper/core';

	SwiperCore.use([Pagination, Navigation, Autoplay]);

	export default {
		name: "School",
		components: {
			Swiper,
			SwiperSlide,
			schoolguide
		},

		data() {
			return {
				fullscreenLoading: true,
				pagination: {
					"clickable": true,
					"bulletActiveClass": "box-01-active",
					"type": 'bullets'
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				titleIndex: 0, // 筛选地区选中
				titleListTwoIndex: 0, // 筛选标题选中
				pull: false, // 是否下拉
				selectList: ['综合排序', '最新', '最热'], // 下拉列表
				seletIndex: 0, // 下拉选中
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				dictTotal: 0,
				size: 16,
				dataObj: {
					country_id: 0, //国家
					profess_id: 0, //专业
					sort: '',
				},
				pagesinfo: {},
				classroomlist: [],
				titleListfont:"综合排序",
				maxnum:'' ,//总条数
			}
		},
		mounted() {
			this.$req({
				method: 'get',
				data: {
					type: 'inspiration'
				},
				url: '/api/tags/schoolTags',
				success: res => {
					this.pagesinfo = res;
					this.pagesinfo.country.unshift({
						name: '全部',
						id: 0
					})
					this.pagesinfo.profess.unshift({
						name: '全部',
						id: 0
					})
				},
				fail: error => {

				}
			});
			this.getlist();
		},
		methods: {
			getlist() {
				if (this.loading || this.state > 1) return;
				let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 16;
				this.$req({
					method: 'get',
					data: {
						type: 'school',
						...this.dataObj,
						page: this.page,
						size: size,
					},
					url: '/api/article',
					success: res => {
						let list = res;
						if (page == 1) {
							this.list = list;
						} else {
							this.list= this.list.concat(list);
						}

						if (list.length > 0) {
						    this.state = 0;
						} else {
						    this.state = 3;
						}
						this.loading = false;
						this.fullscreenLoading =false
					},
					fail: error => {
						if (this.list.length == 0) {
							this.state = 3;
						} else {
							this.state = 2;
						}
						this.loading = false;
						this.fullscreenLoading = false;
					}
				});
				this.$req({
					method: 'get',
					data: {
						type: 'school',
						...this.dataObj,
						show_page:1,
						
					},
					url: '/api/article',
					success: res => {
						console.log(res,'总条数')
						this.maxnum =res;
					},
					fail: error => {
						
					}
				});
			},
			// 假分页
			// paging() {
			// 	let all = this.classroomlist;
			// 	let size = this.size;
			// 	let index = (this.page - 1) * size;
			// 	let arr = [];
			// 	for (let i = 0; i < size; i++) {
			// 		if (index < all.length) {
			// 			arr.push(all[index])
			// 		}
			// 		index++;
			// 	}
			// 	this.list = arr;
			// },
			// 筛选地区
			titleChoose(e) {
				this.titleIndex = e
				this.dataObj.country_id = this.pagesinfo.country[e].id;
				this.list=[];
				this.loading = false;
				this.page=1;
				this.state = 0;
				this.getlist()
			},
			// 筛选专业
			titleTwoChoose(e) {
				this.titleListTwoIndex = e
				this.dataObj.profess_id = this.pagesinfo.profess[e].id
				this.list=[];
				this.loading = false;
				this.page=1;
				this.state = 0;
				this.getlist()
			},
			// 点击下拉
			pullClick() {
				this.pull = !this.pull
			},
			// 下拉选中
			selectChoose(e) {
				this.seletIndex = e
				this.titleListfont = this.selectList[this.seletIndex]
				this.dataObj.sort = e + 1;
				this.list=[];
				this.loading = false;
				this.page=1;
				this.state = 0;
				this.getlist();
				this.pullClick();
			},
			toPart(info) {
				console.log()
				this.$router.push({
					path: '/Detail',
					query: {
						type: 4,
						id: info.link_id
					}
				})
			},
			handleCurrentChange(val) {
				this.page = val;
				this.list = [];
				this.loading = false;
				this.getlist();
				 document.documentElement.scrollTop = document.body.scrollTop = 400;
			},
		}
	}
</script>

<style lang="less" >
	
	.swiper-box-index {
		height: 500px;
		// min-width: 3000px;
		.swiper-index {
			height: 500px;
		}

		.swiper-slide {
			text-align: center;
			font-size: 18px;
			background: #fff;

			/* Center slide text vertically */
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
		}

		.swiper-slide img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.swiper-button-prev::after {
			content: '';
		}
		
		.swiper-button-next::after {
			content: '';
		}


		.swiper-button-prev {
			width: 36px;
			height: 36px;
			background: url(../../assets/img/prev.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			left: 10px;
			right: auto;
			color: #000000;
		}

		.swiper-button-next {
			width: 36px;
			height: 36px;
			background: url(../../assets/img/next.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			right: 10px;
			left: auto;
			color: #000000;
		}

		.swiper-container {
			width: 100%;
			height: 100%;
		}

		.swiper-container {
			margin-left: auto;
			margin-right: auto;
		}

		.img-box {
			background: rgba(0, 0, 0, 0.5);
			transition: all .3s;
			display: none;
		}
		.box-01-active {
			background: #FFFFFF !important;
			opacity: 1 !important;
		}

		.swiper-pagination {
			position: absolute;
			text-align: center;
			transition: .3s opacity;
			transform: translate3d(0, 0, 0);
			z-index: 10;
		}

		.swiper-pagination-bullet {
			width: 8px;
			height: 8px;
			display: inline-block;
			border-radius: 50%;
			background: #000;
			opacity: .2;
			margin: 0 4px;
		}

		.swiper-pagination-bullet:hover {
			cursor: pointer;
		}

		.swiper-pagination-bullets {
			bottom: 10px;
			left: 0;
			width: 100%;
		}
	}

	/* 筛选标题 */
	.title-item {
		min-width: 70px;
		padding: 0 5px;
		height: 28px;
		border-radius: 3px;
		margin-bottom:8px;
	}

	/* 排序 */
	.sort {
		width: 90rpx;
		height: 28px;
		border: 1px solid #010085;
		border-radius: 3px;
		padding: 0 8px;

		.pull-pic {
			width: 14px;
			height: 14px;
			transition: all 0.3s;
		}

		.rotate {
			transform: rotate(180deg);
		}

		.triangle {
			width: 27px;
			height: 15px;
			top: 28px;
			right: 0;
			z-index: 100;
		}

		.pull-box {
			width: 140px;
			background: #FFFFFF;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
			top: 43px;
			border-radius: 8px;
			left: 0;
			overflow: hidden;
			transition: all 0.3s;

			.check_icon {
				width: 15px;
				height: 15px;
			}
		}
	}

	// 动画过渡
	.animation {
		transition: all 0.3s;
	}

	// 改变组件样式
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999 !important;
	}

	.btn-prev,
	.btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}

	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}
</style>
